import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
const { Money } = sdkTypes;

import css from './OrderBreakdown.module.css';
import LineItemUnknownExtendedMayBe from './LineItemUnknownExtendedMayBe';

const LineItemExtendBookingPriceMaybe = props => {
  const { intl, unitPrice, start, lineItems, isProvider, status } = props;
  const extendedStart = start;

  const formattedExtendedPrice = unitPrice && new Money(unitPrice, 'USD');
  const total = formattedExtendedPrice ? formatMoney(intl, formattedExtendedPrice) : null;

  // Check if the status is declined
  const isDeclined = status === 'declined';

  return total ? (
    <div className={`${css.lineItem} ${isDeclined ? css.declinedBackground : ''}`}>
      {/* Display Declined text if status is declined */}
      {isDeclined && (
        <span className={css.declinedText}>
          <FormattedMessage id="OrderBreakdown.declinedText" defaultMessage="Declined" />
        </span>
      )}
      <div className={css.iteminfo}>
        <span className={css.itemLabel}>
          <FormattedMessage id="OrderBreakdown.extendbookingFrom" values={{ extendedStart }} />
        </span>
        <span className={css.itemValue}>{total}</span>
      </div>
      <div className={css.iteminfo}>
        <LineItemUnknownExtendedMayBe lineItems={lineItems} isProvider={isProvider} intl={intl} />
      </div>
    </div>
  ) : null;
};

LineItemExtendBookingPriceMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  code: propTypes.lineItemUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemExtendBookingPriceMaybe;
